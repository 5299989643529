//Раскрытие закрытие меню Категории
/* Добавляем событие клика на кнопку "Категории" */
const accordion = document.getElementsByClassName('accordion')[0];
if(accordion){
	accordion.addEventListener('click', function(event) {
		/* Переключаем класс "active" для кнопки, чтобы она меняла иконку */
		this.classList.toggle('active');
		/* Получаем панель с пунктами меню */
		let panel = document.getElementsByClassName('panel')[0];
		/* Показываем или скрываем панель */
		if (panel.classList.contains("show")) {
			panel.classList.remove("show");
		} else {
			panel.classList.add("show");
		} 
	});
}
const kategiriiSetActive = ()=>{
	const alinks = document.querySelectorAll('.panel a');
	for(let i=0; i<alinks.length;i++){
		if(alinks[i].classList.contains('active')){
			alinks[i].classList.remove('active');
		}
	}
	switch(document.kategoria){
		case 'narco':
			alinks[2].classList.add('active');
			break;
		case 'cardio':
			alinks[0].classList.add('active');
			break;
		case 'infect':
			alinks[1].classList.add('active');
			break;
		default:
	}
}
document.addEventListener('kategoria-achieved',kategiriiSetActive);